@use "../abstracts/variables" as *;
@use "../abstracts/functions";
@use "./icomoon.scss";

body {
  margin: 0;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  --bs-form-invalid-border-color: var(--fail-color);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.legacy {
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
}

//----------------------------
// LOADER
//----------------------------
@keyframes spinner-loader-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &.loader-full {
    width: 100vw;
    height: 100vh;
  }
}

.spinner-loader {
  display: inline-block;
  width: 4rem;
  height: 4rem;

  &:after {
    content: " ";
    display: block;
    width: 80%;
    height: 80%;
    margin: 10%;
    border-radius: 50%;
    border: 0.5rem solid $color__white;
    border-color: $color__white transparent $color__white transparent;
    animation: spinner-loader-anim 1.2s linear infinite;
  }

  &.dark:after {
    border: 0.5rem solid var(--brand-color);
    border-color: var(--brand-color) transparent var(--brand-color) transparent;
  }
}

.hidden-input {
  display: none;
}
