// Screen sizes
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$color__white: #ffffff;
$color__red: #f93c20;
$color__yellow: #ffb200;
$color__yellow_light: #edd085;
$color__cyan: cyan;
$color__black: #000000;
$color_grey: #a7a4a4;
$color__lavande: #d2addb;
$color__green: #6aff23;

$color__primary-dark: #231e25;
$color__primary: #75309a;
$color__primary-light1: #9d67aa;
$color__primary-light2: #c34071;

$color__secondary-dark: #60576f;
$color__secondary: #968ea4;
$color__secondary-light1: #e8e5ec;
$color__secondary-light2: #f6f5f8;

$color__ternary-dark: #3385ca;
$color__ternary: #0092a6;
$color__ternary-light1: #82edd0;

$color__neutral-700: #1f1f1f;
$color__neutral-600: #383838;
$color__neutral-500: #8a8787;
$color__neutral-400: #dbdbdb;
$color__neutral-300: #e2e2e2;
$color__neutral-200: #ededed;
$color__neutral-100: #f1f1f1;
$color__neutral-50: #ffffff;
$color__neutral-700_opacity: rgba(#1f1f1f, 0.8);

$color__timeline-active-border: #ffb200;
$color__timeline-active-item: #fff4da;

$fadeInVideoTime: 300ms;

$card-type: (
  Bronze: (
    color1: #ea9f8e,
    color2: #f2c1aa,
    imageSuffix: "bronze"
  ),
  Argent: (
    color1: #d5e4ec,
    color2: #dbe8ef,
    imageSuffix: "argent"
  ),
  Or: (
    color1: #f8e672,
    color2: #fbe86c,
    imageSuffix: "or"
  ),
  Platine: (
    color1: #a0e7f1,
    color2: #88ccd6,
    imageSuffix: "platine"
  ),
  Diamant: (
    color1: #e9c2f2,
    color2: #be8fc9,
    imageSuffix: "diamant"
  ),
  ComportementAgressif: (
    color1: $color__red,
    color2: $color__red,
    imageSuffix: "agressive"
  ),
  AccepterExprimerLimites: (
    color1: $color__primary-light2,
    color2: $color__primary-light2,
    imageSuffix: "limites"
  ),
  // we use the same images & colors as for "AccepterExprimerLimites" for "SegmenterEntretien"
  SegmenterEntretien:
    (
      color1: $color__primary-light2,
      color2: $color__primary-light2,
      imageSuffix: "limites"
    )
);
