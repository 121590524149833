@use '../../abstracts/variables' as *;
@use '../../abstracts/functions';
@use '../../abstracts/mixins';

.legacy {
  .exercise-intro {
    height: calc(100% - 6rem);
  }

  .exercise-intro-retry-content {
    .nav-tabs {
      position: relative;
      border: 1px solid #684556;
      border-radius: 24px;
      padding: 3px;
    }

    .nav-item {
      width: 50%;
      z-index: 5;

      &:before {
        display: none;
      }

      button {
        width: 100%;
        border: none;
        background: transparent;
        z-index: 5;
        color: $color__secondary;
      }
    }

    .nav-tabs::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: calc(100% - 6px);
      margin: 3px 0;
      width: 50%;
      left: 3px;
      background: rgba(54, 18, 35, 1);
      border-radius: 24px;
      /* Couleur de l'indicateur */
      transition: left 450ms ease-in-out;
      z-index: 2;
    }

    .nav-tabs[tab-active='objectives']::before {
      left: calc(50% - 3px);
    }

    .nav-link {
      color: rgb(104 69 86 / 70%) !important;
    }

    .nav-link.active {
      position: relative;
      border: none !important;
      background: transparent !important;
      color: $color__white !important;
    }

    .tab-content {
      padding: 10px;
    }

    .exercise-intro-desc {
      ul {
        font-size: 16px;
        padding: 0px;
        list-style: none;
        text-align: left;

        @include mixins.lgMinBreakPoint {
          font-size: 18px;
        }

        li {
          position: relative;
          margin: 5px 0;
          padding-left: 15px;

          @include mixins.lgMinBreakPoint {
            margin: 8px 0;

            padding-left: 20px;
          }

          &::before {
            content: '';
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 5px;
            height: 5px;

            @include mixins.lgMinBreakPoint {
              top: 12px;
              width: 7px;
              height: 7px;
            }

            background-color: $color__yellow;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
