@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --brand-color: theme(colors.brand);
    --advice-color: theme(colors.advice);
    --good-color: theme(colors.good);
    --bad-color: theme(colors.bad);
    --fail-color: theme(colors.fail);
    --speak-color: theme(colors.speak);
    --speak-contrast-color: theme(colors.speak-contrast);
    --waiting-for-speak-color: #ffb200;
    --waiting-for-speak-contrast-color: theme(colors.black);
    --brand-watermark: url("data:image/svg+xml;charset=UTF-8,%3csvg width='1777' height='1594' viewBox='0 0 1777 1594' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M559.767 1280.25C482.013 1280.25 409.044 1263.93 342.91 1233.91C430.234 1451.47 618.894 1593.88 849.251 1593.88H1777L1591.93 1384.19V302.064L1302.27 302.87V1037.51L1487.35 1280.25H559.767Z' fill='%23FFEFCC'/%3e%3cpath d='M342.908 1234.11C309.243 1150.11 294.376 1054.83 290.617 952.504C283.781 772.218 289.762 303.07 849.42 303.07H1302.44V0.107422H1014.5H559.765C240.547 0.107422 0.961914 273.861 0.961914 638.866C0.961914 917.051 138.014 1141.25 342.908 1234.11Z' fill='%23FFEFCC' /%3e%3c/svg%3e");
  }

  html {
    @apply bg-white;
  }

  body {
    overflow: auto; // needed to fix the scroll issue in fullscreen

    @apply bg-off-white font-sans font-light text-brand;

    background-image: var(--brand-watermark);
    background-size: 1777px auto;
    background-repeat: no-repeat;
    background-position: calc(0% - 1150px) 100px;
    background-attachment: fixed;

    @screen sm {
      /*1594+1000*/
      --brand-watermark-with-space-top: url("data:image/svg+xml;charset=UTF-8,%3csvg width='1777' height='2594' viewBox='0 0 1777 2594' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='translate(0 1000) '%3e%3cpath d='M559.767 1280.25C482.013 1280.25 409.044 1263.93 342.91 1233.91C430.234 1451.47 618.894 1593.88 849.251 1593.88H1777L1591.93 1384.19V302.064L1302.27 302.87V1037.51L1487.35 1280.25H559.767Z' fill='%23FFEFCC'/%3e%3cpath d='M342.908 1234.11C309.243 1150.11 294.376 1054.83 290.617 952.504C283.781 772.218 289.762 303.07 849.42 303.07H1302.44V0.107422H1014.5H559.765C240.547 0.107422 0.961914 273.861 0.961914 638.866C0.961914 917.051 138.014 1141.25 342.908 1234.11Z' fill='%23FFEFCC' /%3e%3c/g%3e%3c/svg%3e");
      --brand-watermark-with-space-bottom: url("data:image/svg+xml;charset=UTF-8,%3csvg width='1777' height='2594' viewBox='0 0 1777 2594' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M559.767 1280.25C482.013 1280.25 409.044 1263.93 342.91 1233.91C430.234 1451.47 618.894 1593.88 849.251 1593.88H1777L1591.93 1384.19V302.064L1302.27 302.87V1037.51L1487.35 1280.25H559.767Z' fill='%23FFEFCC'/%3e%3cpath d='M342.908 1234.11C309.243 1150.11 294.376 1054.83 290.617 952.504C283.781 772.218 289.762 303.07 849.42 303.07H1302.44V0.107422H1014.5H559.765C240.547 0.107422 0.961914 273.861 0.961914 638.866C0.961914 917.051 138.014 1141.25 342.908 1234.11Z' fill='%23FFEFCC' /%3e%3c/svg%3e");

      background-image: var(--brand-watermark-with-space-bottom),
        var(--brand-watermark-with-space-top);
      background-size: 100vw auto;
      background-repeat: repeat-y;
      background-position:
        calc(0% - 55vw) 0,
        calc(100% + 55vw) 0;
      background-attachment: local;
    }
  }

  .not-important-opacity-0 {
    opacity: 0;
  }

  .not-important-max-h-0 {
    max-height: 0;
  }

  b,
  strong {
    @apply font-semibold;
  }

  *::-moz-selection {
    @apply bg-brand text-white;
  }

  *::selection {
    @apply bg-brand text-white;
  }

  .list-disc {
    @apply pl-6;
  }

  .list-disc li::marker {
    content: '•  ';
  }

  .list-disc li:not(:first-child) {
    @apply mt-2;
  }

  .list-decimal {
    padding: 0 !important;
    counter-reset: counter;
  }

  .list-decimal > li {
    @apply relative pl-8;
  }

  .list-decimal > li::before {
    counter-increment: counter;
    content: counter(counter) '.';
    @apply absolute left-0 top-1/2 -translate-y-1/2 text-2xl font-semibold;
  }
}

@layer components {
  .link {
    @apply font-semibold underline transition-colors #{!important};
    @apply text-brand hover:text-light-brand #{!important};
  }

  .cta {
    @apply relative select-none overflow-hidden rounded-2lg text-sm font-semibold #{!important};
  }

  .cta:disabled {
    @apply opacity-50 #{!important};
  }

  .cta:not(:disabled):not(.cta--without-hover-effect)::before {
    content: '';
    @apply absolute left-1/2 top-full -translate-x-1/2 -translate-y-1/4;
    @apply rounded-full bg-current p-[100%];
    @apply scale-[0.5] opacity-10 transition-all duration-500 ease-linear;
  }

  .cta:not(:disabled):not(.cta--without-hover-effect):hover::before {
    @apply -translate-y-1/2 scale-[1] opacity-20;
  }

  .cta:not(.cta--smaller) {
    @apply px-6 py-4 #{!important};
  }

  .cta--smaller {
    @apply px-4 py-2 #{!important};
  }

  .cta--accent {
    @apply bg-accent text-white #{!important};
  }

  .cta--speak {
    @apply bg-speak text-speak-contrast text-white #{!important};
  }

  .cta--white {
    @apply bg-white text-accent #{!important};
  }

  .cta--accent::before,
  .cta--speak::before,
  .cta--white::before {
    @apply bg-gray/60;
  }

  .cta--accent:not(:disabled):not(.cta--without-hover-effect)::before,
  .cta--white:not(:disabled):not(.cta--without-hover-effect)::before {
    @apply bg-gray/60;
  }

  a.cta {
    @apply inline-block #{!important};
  }
}

@layer utilities {
  .centered-row {
    @apply mx-auto w-full px-4 #{!important};
    max-width: calc(theme('maxWidth.row') + theme('spacing.8')) !important;
  }

  .centered-wide-row {
    @apply mx-auto px-4 #{!important};
    max-width: calc(theme('maxWidth.wide-row') + theme('spacing.8')) !important;
  }

  .scroll-container {
    height: 100vh;
    overflow-y: auto;
  }

  @screen sm {
    .centered-row {
      @apply px-6 #{!important};
      max-width: calc(theme('maxWidth.row') + theme('spacing.12')) !important;
    }

    .centered-wide-row {
      @apply px-6 #{!important};
      max-width: calc(theme('maxWidth.wide-row') + theme('spacing.12')) !important;
    }
  }
}
