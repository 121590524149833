@use "../abstracts/functions";

@font-face {
  font-family: "icomoon";
  src:
    functions.font("icomoon.ttf?mw673m") format("truetype"),
    functions.font("icomoon.woff?mw673m") format("woff"),
    functions.font("icomoon.svg?mw673m#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-camera-off:before {
  content: "\e940";
}
.icon-camera:before {
  content: "\e941";
}
.icon-Fin_goals:before {
  content: "\e939";
}
.icon-Fin_Insultes:before {
  content: "\e93a";
}
.icon-Fin_Moyenne:before {
  content: "\e93b";
}
.icon-Fin_Recentrage:before {
  content: "\e93c";
}
.icon-Fin_Encouragement:before {
  content: "\e93d";
}
.icon-Fin_Perfect_Shot:before {
  content: "\e93e";
}
.icon-Fin_Non_Intervention:before {
  content: "\e93f";
}
.icon-mic:before {
  content: "\e937";
}
.icon-cam:before {
  content: "\e938";
}
.icon-agenda:before {
  content: "\e900";
}
.icon-apple:before {
  content: "\e901";
}
.icon-arrow-angle:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-bell:before {
  content: "\e905";
}
.icon-check:before {
  content: "\e906";
}
.icon-chevron-double:before {
  content: "\e907";
}
.icon-chevron-down:before {
  content: "\e908";
}
.icon-chevron-right:before {
  content: "\e909";
}
.icon-cross:before {
  content: "\e90a";
}
.icon-download:before {
  content: "\e90b";
}
.icon-exit:before {
  content: "\e90c";
}
.icon-eye:before {
  content: "\e90d";
  color: #463977;
}
.icon-facebook:before {
  content: "\e90e";
  color: #463977;
}
.icon-google .path1:before {
  content: "\e90f";
  color: rgb(66, 133, 244);
}
.icon-google .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-google .path3:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}
.icon-google .path4:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-hand:before {
  content: "\e913";
}
.icon-help:before {
  content: "\e914";
}
.icon-info:before {
  content: "\e915";
}
.icon-linkedin:before {
  content: "\e916";
  color: #463977;
}
.icon-logout:before {
  content: "\e917";
}
.icon-mail:before {
  content: "\e918";
}
.icon-map-marker:before {
  content: "\e919";
  color: #463977;
}
.icon-pause:before {
  content: "\e91a";
}
.icon-phone:before {
  content: "\e91b";
}
.icon-phone-alt:before {
  content: "\e91c";
  color: #fff;
}
.icon-play:before {
  content: "\e91d";
  color: #fff;
}
.icon-play-rounded:before {
  content: "\e91e";
  color: #463977;
}
.icon-plus:before {
  content: "\e91f";
}
.icon-raising-hand-1:before {
  content: "\e920";
}
.icon-raising-hand-2:before {
  content: "\e921";
}
.icon-raising-hand-3:before {
  content: "\e922";
}
.icon-raising-hand-4:before {
  content: "\e923";
}
.icon-screen-share:before {
  content: "\e924";
}
.icon-search:before {
  content: "\e925";
}
.icon-share:before {
  content: "\e926";
  color: #463977;
}
.icon-speaking-1:before {
  content: "\e927";
  color: #463977;
}
.icon-speaking-2:before {
  content: "\e928";
  color: #463977;
}
.icon-speaking-3:before {
  content: "\e929";
  color: #463977;
}
.icon-speaking-4:before {
  content: "\e92a";
  color: #463977;
}
.icon-speaking-5:before {
  content: "\e92b";
  color: #463977;
}
.icon-speech:before {
  content: "\e92c";
}
.icon-star:before {
  content: "\e92d";
  color: #463977;
}
.icon-timer:before {
  content: "\e92e";
}
.icon-twitter:before {
  content: "\e92f";
  color: #463977;
}
.icon-user:before {
  content: "\e930";
}
.icon-waiting:before {
  content: "\e931";
  color: #fff;
}
.icon-wait-speaking-1:before {
  content: "\e932";
  color: #463977;
}
.icon-wait-speaking-2:before {
  content: "\e933";
  color: #463977;
}
.icon-wait-speaking-3:before {
  content: "\e934";
  color: #463977;
}
.icon-wait-speaking-4:before {
  content: "\e935";
  color: #463977;
}
.icon-wait-speaking-5:before {
  content: "\e936";
  color: #463977;
}
