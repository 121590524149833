@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_variables-dark";
@import "bootstrap/scss/_maps";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_utilities";
@import "bootstrap/scss/_root";
@import "bootstrap/scss/_helpers";
@import "bootstrap/scss/_modal";

.legacy {
    @import "bootstrap/scss/utilities/_api";
}

.legacy {
    @import "bootstrap/scss/mixins/_banner";
    @include bsBanner("");

    @import "bootstrap/scss/_reboot";
    @import "bootstrap/scss/_nav";
    @import "bootstrap/scss/_navbar";

    color: rgb(54, 18, 35);

    & {
        margin: 0; // 1
        -webkit-text-size-adjust: 100%; // 3
        -webkit-tap-highlight-color: rgba($black, 0); // 4
    }

    h6,
    .h6,
    h5,
    .h5,
    h4,
    .h4,
    h3,
    .h3,
    h2,
    .h2,
    h1,
    .h1,
    b,
    strong {
        font-weight: 600;
    }
}

.legacy {
    @import "bootstrap/scss/_type";
    @import "bootstrap/scss/_images";
    @import "bootstrap/scss/_containers";
    @import "bootstrap/scss/_grid";
    @import "bootstrap/scss/_tables";
    @import "bootstrap/scss/_forms";
    @import "bootstrap/scss/_buttons";
    @import "bootstrap/scss/_transitions";
    @import "bootstrap/scss/_dropdown";
    @import "bootstrap/scss/_button-group";
    @import "bootstrap/scss/_card";
    @import "bootstrap/scss/_accordion";
    @import "bootstrap/scss/_breadcrumb";
    @import "bootstrap/scss/_pagination";
    @import "bootstrap/scss/_badge";
    @import "bootstrap/scss/_alert";
    @import "bootstrap/scss/_progress";
    @import "bootstrap/scss/_list-group";
    @import "bootstrap/scss/_close";
    @import "bootstrap/scss/_toasts";

    @import "bootstrap/scss/_tooltip";
    @import "bootstrap/scss/_popover";
    @import "bootstrap/scss/_carousel";
    @import "bootstrap/scss/_spinners";
    @import "bootstrap/scss/_offcanvas";
    @import "bootstrap/scss/_placeholders";
}
