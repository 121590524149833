@use "../../abstracts/variables" as *;
@use "../../abstracts/functions";
@use "../../abstracts/mixins";

%title {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.legacy .new-step {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  // center content
  .bottom-bar,
  &__text {
    margin-top: auto;
  }

  .bottom-bar {
    padding-bottom: 0;
    height: auto;
    flex: 0 0 auto;
  }

  &__text {
    margin-left: auto;
    margin-right: auto;
    max-width: $screen-xl;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
