@use "../abstracts/variables" as *;
@use "../abstracts/mixins";
@use "../abstracts/functions";
@use "../abstracts/animations";

.range {
    .range__input {
        width: 100%;
        order: 0;
        flex-grow: 1;
        -webkit-appearance: none;
        margin: 0 1rem;
        height: 0.5rem;
        background: #e8e5ec;
        border-radius: 0.25rem;
        background-image: linear-gradient(270deg, #81539d 2.05%, $color__primary-light2 100%);
        background-repeat: no-repeat;
    }

    /* Input Thumb */
    $thumbs: (-ms-thumb, -moz-range-thumb, -webkit-slider-thumb);
    @each $thumb in $thumbs {
        .range__input::#{$thumb} {
            -webkit-appearance: none;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            background: $color__primary;
            cursor: ew-resize;
            box-shadow: 0px 2px 3px rgba(60, 49, 104, 0.2);
            transition: background 0.3s ease-in-out;
            outline: none;
        }
        .range__input::#{$thumb}:hover {
            background: $color__primary-dark;
        }
    }

    /* Input Track */
    $thumbs: (-ms-track, -moz-range-track, -webkit-slider-runnable-track);
    @each $thumb in $thumbs {
        .range__input::#{$thumb} {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }
    }
}
