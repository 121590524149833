.loader {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  z-index: 90;
}

.loader--static {
  position: static;
}

.loader__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}

.loader__bar {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff !important;
  border-color: #fff transparent #fff transparent !important;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
