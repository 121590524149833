@use '../abstracts/variables' as *;
@use '../abstracts/mixins';
@use '../abstracts/functions';
@use '../abstracts/animations';

.achievement-container{
    width:600px;
    margin:0 auto;
    @media (max-width: $screen-lg) {
        width:550px;
    }
    @media (max-width: 610px) {
        width: 400px;
    }
}
.achievement {
    border: 1px solid #E8E5EC;
    border-radius: 5px;
    min-height: 60px;

    position: relative;
    background: linear-gradient(90deg, $color__secondary-light2 60px, #ffffff 60px, #ffffff 100%, $color__secondary-light2 100%);
    margin:  0;
    width:100%;
    @media (max-width: $screen-lg) {
        margin:0;
    }
    @media (max-width: 610px) {
        margin:0;
    }
    svg {
        max-height: 100%;
        height: 60px !important;
    }
    .achievement-row{
        display: flex;
        min-height: 60px;
        align-items: center;
    }
    .icon-animation-lottie{
        flex:0 0 60px
    }    

    &.achievement-unlock-new {
        background-image: linear-gradient(to right, #FFFFFF 50%, $color__secondary-light1 0);
        background-position: right;
        background-size: 200% 200%;
        transition: background-position 600ms;

        .achievement-more,
        .icon-check:before {
            opacity: 0;
        }
    }

    @media (max-width: $screen-xl) {
        min-height: 40px;
        background: linear-gradient(90deg, $color__secondary-light2 40px, #ffffff 40px, #ffffff 100%, $color__secondary-light2 100%);
        .achievement-row{
            min-height: 40px;
        }
        svg {
            height: 40px !important;
        }
        .icon-animation-lottie{
            flex : 0 0 40px;
        }

    }

    .achievement-icon {
        width: 60px;
        height: 60px;
        background: $color__secondary;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;

        @media (max-width: $screen-xl) {
            width: 40px;
            height: 40px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }

        &.icon-lock:before {
            background: url(../../assets/icons/lock.png) center center no-repeat;
        }

        /*
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
        &.icon-lock-animate{
            background: transparent;
        }
        &.icon-check:before {
            background: url(../../assets/icons/check.png) center center no-repeat;
        }

        &.icon-lock:before {
            background: url(../../assets/icons/lock.png) center center no-repeat;
        }

        &.icon-lock-animate:before {
            
            background: url(../../assets/icons/arceau.png) center center no-repeat;
            background-size: 23px 28px;
        }
        &.icon-lock-animate:after {
            
            background: url(../../assets/icons/cadenas.png) center center no-repeat;
            background-size: 23px 28px;
        }*/
        @media (max-width: $screen-xl) {
            height: 40px;
            width: 40px;
            flex: 0 0 40px;
            /*&.icon-check:before,
            &.icon-lock:before{
                background-size:13px;
            }
            &.icon-lock-animate:before,
            &.icon-lock-animate:after {
                background-size: 13px
            }*/
        }
    }
    .achievement-icon-image {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        &.achievement-icon-goals{
            img{
            opacity: 0.8;
            }
        }
        @media (max-width: $screen-xl) {
            width: 40px;
            height: 40px;
            font-size: 1.25em;
        }
    }
    /*&:hover {
        .achievement-icon {
            background-color: $color__primary-light1;
            background-size: 21px;
            &.icon-lock-animate{
                background: transparent;
            }
            &.icon-lock,&.icon-check{
            &:before {
                transform: rotate(-8deg) scale(1.1);
            }
            }
        }

    }

    &.achievement-unlock-animate {
        background-position: left;
        .achievement-more,
        .icon-check:before {
            animation: iconCheck 1200ms;
            animation-fill-mode: forwards;
        }
        .icon-lock-animate{
            &:after{
            animation: iconUnlock1 800ms;
            animation-fill-mode: forwards;

            }
            &:before{
                animation: iconUnlock2 800ms;
                animation-fill-mode: forwards;
                }
        }
    }*/


    .achievement-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
        padding: 0 20px;

        @media (max-width: $screen-xl) {
            font-size: 16px;
            line-height: 24px;
            padding: 0 10px;
        }
    }

    .achievement-more-toggle {
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;
        flex: 0 0 120px;
        svg {
            height: 13px !important;
        }
    }

    .achievement-more {
        margin-left: 80px;
        font-size: 14px;
        margin-right:5px;
        margin-bottom:5px;
        @media (max-width: $screen-xl) {
            margin-left: 50px;
        }

        span {
            padding-right: 15px;

            @media (max-width: $screen-xl) {
                padding-right: 5px;
            }
        }
    }

    &.achievement-mouse-leave,
    &.achievement-mouse-enter {
        .achievement-icon {
            transition: all 0.3s linear;
        }
    }

    &.achievement-mouse-leave,
    &.achievement-mouse-enter {
        .achievement-icon:before {
            transition: all 0.1s linear;
        }
    }

    &.achievement-small {
        .achievement-title {
            font-size: 16px;
            line-height: 24px;
            padding: 0 10px;

            @media (max-width: $screen-xl) {
                font-size: 14px;
                line-height: 16px;
                padding: 6px;
            }
        }
    }
}

.feedback-nav {
    display: flex;
}

.no-achievement {
    margin: 20px 0;
}