@use "../abstracts/variables" as *;
@use "../abstracts/functions";
@use "../abstracts/mixins";

.exercise--start .exercise__content {
  max-height: 100% !important;
  display: flex;
  list-style-type: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/***********************************************
 * LAYOUT
 ***********************************************/
.exercise {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;

  &--step-welcome,
  &--step-materiel,
  &--step-authorization,
  &--step-configuration {
    .exercise__bottom-bar {
      display: none;
      height: auto;
      flex: 0 0 auto;
      padding-bottom: 0;
    }
  }

  &--step-waitWebcam {
    &.exercise--media-refuse .exercise__bottom-bar {
      display: none;
      height: auto;
      flex: 0 0 auto;
      padding-bottom: 0;
    }
  }
}

/***********************************************
 * CONTENT
 ***********************************************/

.exercise__inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;

  &.exercise_error_message {
    justify-content: center;
    align-items: center;
  }
}

.exercise__content {
  flex: 1 1 auto;
  width: 100%;
  margin: 0 auto;
}

/***********************************************
* MIC VALIDATION
 ***********************************************/

.mic-validation-instruction {
  opacity: 0;
  animation: fadein1Sec 1s ease-in-out 1s forwards;
  transition: text-shadow 0.3s ease-in-out;
}

@keyframes fadein1Sec {
  to {
    opacity: 1;
  }
}

@keyframes highlightLink {
  0%,
  10%,
  20%,
  100% {
    color: $color__yellow;
    text-shadow: 0 0 0 rgba(255, 255, 255);
  }

  5%,
  15% {
    color: $color__yellow;
    text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
  }
}

.fadeIn500ms,
.fadeIn3,
.fadeIn5,
.fadeIn7 {
  opacity: 0;
  animation: fadein5Sec 1s ease-in-out 3s forwards;
  font-size: 1.5rem;
  pointer-events: none;
}

.fadeIn500ms {
  animation: fadein5Sec 1s ease-in-out 500ms forwards;
}

.fadeIn5 {
  animation: fadein5Sec 1s ease-in-out 5s forwards;
}

.fadeIn7 {
  animation: fadein5Sec 1s ease-in-out 7s forwards;
}

@keyframes fadein5Sec {
  0% {
    opacity: 0;
    pointer-events: none;
  }

  99% {
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: auto;
  }
}

.highlight {
  &:after {
    content: "";
    position: absolute;
    width: 107px;
    height: 46px;
    padding: 0px;
    bottom: 4px;
    right: 0;
    margin: 10px 20px;
    border-radius: 23px;
    animation: halo 2s linear infinite;
  }
}

@keyframes halo {
  0% {
    box-shadow: 0 0 12px rgba(255, 255, 255, 0);
  }

  50% {
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.4);
  }

  100% {
    box-shadow: 0 0 12px rgba(255, 255, 255, 0);
  }
}

/***********************************************
 * BRIEFING
 ***********************************************/
.exercise-briefing {
  .briefing__inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .briefing__participant {
    /*min-height: 250px;*/
    width: 100%;
    max-width: 400px;
    background: grey;
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;
  }

  .briefing__speaker {
    width: 265px;
    max-width: 100%;
    padding: 1rem;
    margin: 0px auto;
  }

  .briefing__speaker__inner {
    position: relative;
    padding-bottom: 100%;
    border-radius: 50%;
    overflow: hidden;
  }

  .briefing__speaker__video {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: grey;
  }

  @media (min-width: 768px) {
    .exercise-briefing--0 .briefing__speaker {
      margin-left: auto;
      margin-right: 0px;
    }
  }

  .briefing__desc {
    border-left: 3px solid #fff;
    padding: 2rem 1rem 2rem;
  }

  .briefing__icon {
    font-size: 4rem;
    margin-bottom: 0.5rem;
    line-height: 1;
    margin-top: -1rem;
  }

  .briefing__title {
    font-size: 1.75rem;
    text-transform: uppercase;
    margin: 0rem 0rem 0.5rem;
    font-weight: bold;
  }

  .briefing__subtitle {
    font-size: 1.25rem;
    margin: 0rem;
  }

  @media (max-width: 575px) {
    .briefing__desc {
      border-left: none;
      border-top: 3px solid #fff;
      text-align: center;
    }
  }
}

.participant--waiting,
.participant--speaking {
  .wrapper::before {
    content: "";
    position: absolute;
    display: block;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    border: 4px solid var(--speak-color, cyan);
    z-index: 3 !important;
    border-radius: 1.25rem;
  }

  .legacy & .name {
    background-color: var(--speak-color, cyan) !important;
    color: var(--speak-contrast-color) !important;
    font-weight: 600;
  }
}

.participant--raising {
  .wrapper::before {
    content: "";
    position: absolute;
    display: block;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    border: 4px solid var(--waiting-for-speak-color, $color__yellow) !important;
    z-index: 3 !important;
    border-radius: 1.25rem;
  }

  .legacy & .name {
    background-color: var(--waiting-for-speak-color, $color__yellow) !important;
    color: var(--waiting-for-speak-contrast-color) !important;
    font-weight: 600;
  }
}

.participant--no-camera,
.participant--not-connected {
  .video_layer {
    background: $color__primary;
    background: linear-gradient(135deg, $color__primary 0%, $color__primary-light1 100%);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.video_layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.video_layer {
  transition: opacity $fadeInVideoTime;
}

.video_layer video {
  display: block;
  width: 100%;
}

.debug_graph_controls {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: rgba(200, 200, 200, 0.8);
  border-radius: 10px;
  padding: 1rem;
  z-index: 1056;
  overflow: auto; /* Ajout pour gérer le dépassement de contenu */
  max-height: 90%; /* S'assurer que la div ne dépasse pas la hauteur de l'écran */
}

.debug-button {
  text-transform: none;
  font-size: 1rem;
  padding: "0px 1px";
}

.debug_graph_controls_container {
  background-color: rgba(80, 50, 80, 0.5);
  padding: 10px;
}

.debug_graph_controls_row {
  justify-content: center;
}

.debug_graph_controls_sep {
  height: 10px;
}

.toggle-force-useractions-mode {
  display: flex;
  align-items: center;
  //font-size: 15px;
}

.toggle-force-useractions-mode input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.toggle-force-useractions-mode label {
  cursor: pointer;
}

.debug_cache_progression {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  background: rgba(black, 0.5);
  color: white;
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 0.75rem;
  z-index: 20;
  border: 1px solid rgba(white, 0.5);

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      border-bottom: 0px;
    }
  }
}

.achievement-push {
  position: fixed;
  top: 15%;
  right: 1.5rem;
  z-index: 15;

  .achievement-push-item {
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
    border-radius: 5px 0 0 5px;
    width: 250px;
    margin-right: -260px;
    height: 50px;
    padding: 5px;
    animation: showAnimation 500ms ease-in-out 200ms forwards;
    color: $color__primary-dark;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 10px;

    svg {
      max-height: 40px;
    }

    .achievement-push-item-content {
      margin-left: 15px;

      .achievement-push-item-type {
        font-size: 10px;
        line-height: 12px;
        margin: 3px 0;
        text-transform: initial;
        color: $color__secondary-dark;
      }
    }
  }

  @keyframes showAnimation {
    from {
      margin-right: -260px;
    }

    to {
      margin-right: 0px;
    }
  }

  .hide {
    display: none;
  }

  .fade-out {
    opacity: 0;
    transform: translateY(-100px);
    transition:
      opacity 0.5s ease-in-out,
      transform 0.5s ease-in-out;
  }

  .achievement-push-item.one-to-one {
    border-radius: 24px;
    font-size: 15px;
    padding: 0;
    align-items: center;

    svg {
      margin-left: 6px;
      // we need to use !important because of the inline style
      width: 40px !important;
      height: 40px !important;
    }

    .achievement-push-item-content {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1.4em;

      &.strikethrough {
        font-size: 14px;
        text-decoration: none;
        background-image: linear-gradient($color__neutral-600, $color__neutral-600);
        background-repeat: no-repeat;
        background-position: center left;
        background-size: 0% 1px;
        transition: background-size 200ms ease-in-out;
        animation: strikethrough 150ms ease-in-out 1s forwards;
      }

      @keyframes strikethrough {
        0% {
          background-size: 0% 2px;
        }

        100% {
          background-size: 100% 2px;
        }
      }
    }
  }
}

.forbidden-interaction-warning {
  opacity: 0;
  color: $color__primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(15px);
  z-index: 9;
  transition: opacity 0.5s ease-in-out;

  &.show {
    opacity: 1;
  }

  .close-forbidden-interaction-warning {
    position: absolute;
    top: 0;
    cursor: pointer;
    right: 5px;
  }
}

.tooltip--settings {
  font-size: 75%;
  text-align: left;
}
