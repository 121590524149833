@use "../abstracts/variables" as *;
@use "../abstracts/mixins";
@use "../abstracts/functions";

.legacy.dashboard {
  //-------------------------------------------
  // EXERCISE ITEM
  //-------------------------------------------

  .exercise-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;

    &__inner {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      position: relative;
      transition: all 0.3s;
      border-radius: 5px;
      padding: 1rem 2rem;
      border-radius: 2rem;
      border: 1px solid $color__primary;

      &:hover {
        background: $color__yellow_light;
      }
    }

    &.exercise-item--disabled .exercise-item__inner {
      cursor: not-allowed;
      background: #757575;
    }

    .item__category,
    .item__status,
    .item__replay,
    .item__image,
    .item__level {
      display: none !important;
    }

    .item__description {
      flex: 1 1 auto;
      align-self: center;
      .item__title {
        margin: 0px;
        font-size: 1rem;
      }
    }
    .item__image {
      padding-left: 0.5rem;
      max-width: 100px;
      align-self: center;
    }

    .item__replay {
      width: 50px;
      flex: 0 0 50px;
      text-align: center;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      transition: background 0.3s linear;
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .item__replay_link {
      cursor: pointer;
    }

    .item__status {
      width: 50px;
      flex: 0 0 50px;
      text-align: center;
      font-size: 2rem;
    }
    .item__level {
      display: none;
      text-align: center;
      padding: 5px 5px;
      font-size: 0.825rem;
      font-weight: 600;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .item__level_inner {
        transform: rotate(180deg);
        text-orientation: mixed;
        writing-mode: vertical-rl;
      }
    }

    .exercise-status--done {
      color: #2fff54;
    }
    .exercise-status--todo {
      color: #ffbc2f;
    }
    .exercise-status--progress {
      color: #3ebdff;
    }
    .item__category {
      font-size: 0.825rem;
    }
    .exercise-category {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    .exercise-category__icon {
      margin-right: 5px;
    }
    .exercise-category__name {
      margin: 0px;
    }
  }
}
