@use "../abstracts/variables" as *;
@use "../abstracts/mixins";
@use "../abstracts/functions";
@use "../abstracts/animations";

.feedback-step {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 0.7s;
    //

    &.feedback-step-congrats {
        overflow: hidden;
        //  height: calc(100vh - 190px);
        padding: 1rem 50px;
        justify-content: center;

        @media (max-width: $screen-lg) {
            padding: 0.75rem 50px;
        }

        .feedback-step-inner-congrats {
            justify-content: center;
            text-align: center;
            opacity: 0;
            animation-delay: 150ms;
            animation-duration: 350ms;
            animation-name: bounce-5;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            transform: scale(0.9, 1.1) translateY(100px);
            position: relative;
            font-size: 1.25rem;

            h1 {
                font-style: normal;
                font-weight: 400;
                font-size: 70px;
                line-height: 70px;
                text-align: center;
                text-transform: uppercase;
                margin-top: 5% !important;

                @media (max-width: $screen-lg) {
                    font-size: 50px;
                }
            }

            .feedback-step-congrats-star-left {
                background: functions.image("star-left.svg") no-repeat;
                background-size: contain;
                width: 105px;
                height: 80px;
                opacity: 0;
                position: absolute;
                animation-delay: 150ms;
                animation-duration: 350ms;
                animation-name: starLeft;
                animation-timing-function: ease;
                animation-fill-mode: forwards;
                top: 20px;
                bottom: 0;
                left: 20px;
            }

            .feedback-step-congrats-star-right {
                background: functions.image("star-right.svg") no-repeat;
                background-size: contain;
                width: 105px;
                height: 80px;
                opacity: 0;
                position: absolute;
                animation-delay: 150ms;
                animation-duration: 350ms;
                animation-name: starRight;
                animation-timing-function: ease;
                animation-fill-mode: forwards;
                top: 20px;
                bottom: 0;
                right: 20px;
            }
        }
    }

    .feedback-step-achievements {
        opacity: 0;
        height: 0px;
        animation: opacity 350ms cubic-bezier(0.23, 0.65, 0.04, 0.91);
        animation-fill-mode: forwards;
    }
}

@keyframes hidecongrats {
    0% {
        //opacity: 1;
        height: 100%;
        opacity: 0;
    }

    100% {
        //opacity: 0;
        height: 0px;
        padding: 0;
    }
}

@keyframes starLeft {
    0% {
        top: inherit;
        bottom: 0;
        right: 50%;
        opacity: 0;
        opacity: 0;
    }

    33% {
        opacity: 0;
    }

    100% {
        bottom: inherit;
        top: -35px;
        left: -50px;
        opacity: 1;
    }
}

@keyframes starRight {
    0% {
        top: 20px;
        bottom: 0;
        right: 20px;
    }

    33% {
        opacity: 0;
    }

    100% {
        bottom: inherit;
        top: -35px;
        right: -55px;
        opacity: 1;
    }
}

@keyframes bounce-5 {
    0% {
        opacity: 0;
        transform: scale(1, 1) translateY(100px);
    }

    33% {
        opacity: 1;
        transform: scale(0.9, 1.1) translateY(-5px);
    }

    64% {
        opacity: 1;
        transform: scale(1, 1) translateY(5px);
    }

    100% {
        opacity: 1;
        transform: scale(1, 1) translateY(0);
    }
}

// Content step
.feedback-step__inner {
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;

    @media (max-width: $screen-xl) {
        padding-top: 3rem;
        width: 100%;
    }

    @media (max-width: $screen-lg) {
        padding: 0.75rem;
    }

    .feedback-step-block {
        margin: 3.125rem 0;

        @media (max-width: $screen-xl) {
            margin: 1rem 0;
        }

        @media (max-width: $screen-lg) {
            margin: 0.25rem 0;
        }
    }

    h2 {
        font-size: 1rem;
        text-transform: uppercase;
        color: $color__primary-light1;

        @media (max-width: $screen-lg) {
            margin-bottom: 0.25rem;
        }
    }
}

@media (max-height: 800px) {
    .feedback-step__inner {
        padding: 1rem 1rem 1rem;

        .feedback-step-block {
            margin-top: 0px;
            margin-bottom: 30px;

            @media (max-height: 600px) {
                margin-bottom: 15px;
            }
        }
    }
}

@media (max-height: 600px) {
    .feedback-step-block {
        textarea {
            height: 100px;
        }
    }
}

.feedback__actions {
    display: flex;
    justify-content: space-around;
    max-width: 500px;
    margin: 20px auto 50px;

    @media (max-width: $screen-lg) {
        flex: inherit;
    }

    @media (max-width: $screen-md) {
        width: calc(90% - 20px);
        left: 5%;
    }

    @media (max-width: $screen-md) {
        .btn {
            font-size: 0.75rem;
            padding: 0.75rem 1rem 0.625rem;
        }
    }
}

// Content
.textarea-large {
    height: 200px;
}

.feedback-satisfaction-block {
    display: flex;

    img {
        margin-right: 10px;
        margin-top: 10px;
        display: block;

        @media (max-width: $screen-lg) {
            margin-right: 5px;
            margin-top: 2px;
            height: 27px;
        }
    }
}

.feedback-satisfaction-group {
    flex-grow: 1;
    margin-left: 25px;

    label {
        color: $color__primary;
        font-size: 18px;
    }
}

.feedback-goals {
    display: flex;
    flex-wrap: wrap;

    .feedback-goals-item {
        flex: 0 0 50%;
        gap: 30px;
    }

    @media (max-width: $screen-md) {
        display: block;
    }
}

.button-goal {
    display: flex;
    background: #fff;
    border: 1px solid $color__primary-light2;
    border-radius: 20px;
    height: 40px;
    align-items: center;
    margin: 5px 0;
    font-size: 14px;
    padding: 0 50px;
    width: fit-content;
    cursor: pointer;

    &.button-goal-selected {
        background: url(../../assets/icons/check.png) 15px center no-repeat;
        background-size: 13px;
        background-color: $color__primary;
        color: #fff;
    }

    @media (max-width: $screen-lg) {
        height: 25px;
        padding: 0 20px;

        &.button-goal-selected {
            background-position: 5px center;
            background-size: 10px;
        }
    }
}

.feedback-step-block {
    textarea {
        height: 200px;
        resize: none;

        @media (max-width: $screen-lg) {
            height: 10rem;
        }
    }
}

.retry {
    .feedback-step-block {
        textarea {
            @media (max-width: $screen-lg) {
                height: 6rem;
            }
        }
    }
}

.feedback-ending-desc {
    p {
        @apply my-2 text-sm #{!important};
    }

    ol,
    ul {
        @apply mt-2 list-none pl-6 text-sm #{!important};
    }

    ul li::marker {
        content: "•  ";
    }

    ol {
        list-style: decimal !important;
    }

    ul li:not(:first-child) {
        @apply mt-2;
    }
}
