//----------------------------
// BUTTONS
//----------------------------

// Text
@keyframes btnText1Leave {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-200%);
  }
}
@keyframes btnText1Enter {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes btnText2Enter {
  0% {
    transform: translateY(0%);
    top: 100%;
    opacity: 1;
  }
  100% {
    top: 50%;
    transform: translateY(-50%);
  }
}
@keyframes btnText2Leave {
  0% {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
  100% {
    top: 0%;
    transform: translateY(-200%);
    opacity: 1;
  }
}

// Background
@keyframes btnSlideRight {
  0% {
    left: 0px;
    right: 100%;
  }
  100% {
    right: 0px;
  }
}

@keyframes btnSlideLeft {
  0% {
    left: 0px;
    right: 0px;
  }
  100% {
    left: 100%;
  }
}

@keyframes iconUnlock1 {
  0% {
    transform: translatex(0%) translatey(0%);
  }
  100% {
    transform: translatex(200%) translatey(-200%);
    opacity: 0;
  }
}
@keyframes iconUnlock2 {
  0% {
    transform: translatex(0%) translatey(0%);
  }
  100% {
    transform: translatex(-200%) translatey(200%);
    opacity: 0;
  }
}
@keyframes iconCheck {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes LoginBackGroundShape1 {
  0% {
    width: 49%;
    height: 39%;
    left: 10%;
    top: 1%;
  }
  10% {
    width: 49%;
    height: 39%;
    left: -5%;
    top: 10%;
  }
  60% {
    width: 29%;
    height: 39%;
    left: 95%;
    top: 15%;
  }
  100% {
    width: 49%;
    height: 39%;
    left: 10%;
    top: 1%;
  }
}

@keyframes LoginBackGroundShape2 {
  0% {
    width: 57%;
    height: 33%;
    left: 75%;
    top: 35%;
    transform: matrix(-0.71, -0.99, 0.5, -0.71, 0, 0);
  }
  33% {
    width: 57%;
    height: 45%;
    left: 65%;
    top: 35%;
    transform: matrix(-0.98, -0.37, 0.19, -0.94, 0, 0);
  }
  66% {
    width: 29%;
    height: 39%;
    left: 10%;
    top: 20%;
    transform: matrix(-0.98, -0.37, 0.19, -0.94, 0, 0);
  }
  100% {
    width: 57%;
    height: 33%;
    left: 75%;
    top: 35%;
    transform: matrix(-0.71, -0.99, 0.5, -0.71, 0, 0);
  }
}
@keyframes LoginBackGroundShape3 {
  0% {
    width: 48%;
    height: 38%;
    left: 65%;
    top: -10%;
    transform: matrix(0.99, -0.19, 0.11, 0.98, 0, 0);
  }
  16% {
    width: 57%;
    height: 45%;
    left: 40%;
    top: 35%;
    transform: matrix(0.92, 0.65, -0.32, 0.85, 0, 0);
  }
  42% {
    width: 29%;
    height: 39%;
    left: 20%;
    top: 55%;
    transform: matrix(0.92, 0.65, -0.32, 0.85, 0, 0);
  }
  100% {
    width: 48%;
    height: 38%;
    left: 65%;
    top: -2%;
    transform: matrix(0.99, -0.19, 0.11, 0.98, 0, 0);
  }
}
@keyframes LoginBackGroundShape4 {
  0% {
    width: 55%;
    height: 40%;
    left: 0%;
    top: 38%;
  }
  25% {
    width: 70%;
    height: 60%;
    left: 65%;
    top: 35%;
  }
  50% {
    width: 70%;
    height: 60%;
    left: -5%;
    top: -12%;
  }
  100% {
    width: 55%;
    height: 40%;
    left: 0%;
    top: 38%;
  }
}

.shake-vertical {
  -webkit-animation: shake-vertical 3s linear infinite both;
  animation: shake-vertical 3s linear infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-9-17 16:52:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  80% {
    -webkit-transform: translateY(1.2px);
    transform: translateY(1.2px);
  }
  90% {
    -webkit-transform: translateY(-1.2px);
    transform: translateY(-1.2px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  80% {
    -webkit-transform: translateY(1.2px);
    transform: translateY(1.2px);
  }
  90% {
    -webkit-transform: translateY(-1.2px);
    transform: translateY(-1.2px);
  }
}
