@use "../abstracts/variables" as *;

.debug {
  display: none !important;
}

.debug-mode {
  .debug {
    display: initial !important;
  }

  &:before {
    //content: 'DEBUG ACTIVED / CLICK AGAIN CTRL + B TO DISABLE IT';
    z-index: 3;
    width: 100%;
    height: 22px;
    top: 0;
    color: #fff;
    background: red;
    position: absolute;
  }
}
