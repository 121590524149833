.content :global p:not(.label) {
  @apply mt-2 !important;
}

.content :global ul {
  @apply mt-2 list-none pl-6 !important;
  margin-bottom: 10px;
}

.content :global ul li::marker {
  content: '•  ';
}

.content :global ul li:not(:first-child) {
  @apply mt-2;
}
